<template>
    <div class="add-case-page">
        <page-title :title="'新增案件'">
            <span>新增案件信息</span>
            <el-button type="info" size="mini" style="margin-left:20px;" @click="backPre">返  回</el-button>
        </page-title>
        <div class="case-form">
            <el-form ref="form" :model="form" size="small">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="报案号：" prop="reportCaseNum">
                            <el-input v-model="form.reportCaseNum" placeholder="请输入报案号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="报案时间：" prop="reportTime" :rules="[{ required: true, message: '报案时间不能为空', trigger: 'blur' },{required: true, message: '报案时间不能为空', trigger: 'change' }]">
                            <el-date-picker
                                style="width:100%"
                                v-model="form.reportTime"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="请选择报案时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="6">
                        <el-form-item label="查勘员：" prop="surveyor">
                            <el-input v-model="form.surveyor" placeholder="请输入查勘员"></el-input>
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="8">
                        <el-form-item label="所属区域：" prop="belongArea">
                            <el-select v-model="form.belongArea" placeholder="请选择区域" style="width:100%;">
                                <el-option key="温州" label="温州" value="温州"></el-option>
                                <el-option key="台州" label="台州" value="台州"></el-option>
                                <el-option key="萧山" label="萧山" value="萧山"></el-option>
                                <el-option key="绍兴" label="绍兴" value="绍兴"></el-option>
                                <el-option key="其它" label="其它" value="其它"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <el-form-item label="出险地点：" prop="outDangerAddr">
                            <el-input v-model="form.outDangerAddr" placeholder="请输入出险地点"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="出险单位：" prop="outDangerUnit">
                            <el-input v-model="form.outDangerUnit" placeholder="请输入出险单位"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="出险时间：" prop="outDangerTime">
                            <el-date-picker
                                style="width:100%"
                                v-model="form.outDangerTime"
                                value-format="yyyy-MM-dd"
                                type="date"
                                placeholder="请选择出险时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <!-- <el-col :span="8">
                        <el-form-item label="被保险人：" prop="insurePeo">
                            <el-input v-model="form.insurePeo" placeholder="请输入被保险人"></el-input>
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="8">
                        <el-form-item label=" 联系人：" prop="linkman">
                            <el-input v-model="form.linkman" placeholder="请输入联系人"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="联系方式：" prop="linkmanTel">
                            <el-input v-model="form.linkmanTel" placeholder="请输入联系方式"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="事故原因：" prop="caseDesc">
                            <el-input type="textarea" :rows="4" v-model="form.caseDesc" placeholder="请输入案件描述"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="备注说明：" prop="remark">
                            <el-input type="textarea" :rows="3" v-model="form.remark" placeholder="请输入备注"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="选择维修工：" prop="repairPeo">
                            <el-select v-model="form.repairPeo" placeholder="请选择维修工">
                                <el-option
                                    v-for="item in repairPeoList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                    <span style="float: left">{{ item.name }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row> -->
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item style="text-align:center;">
                            <el-button type="primary" @click="onSubmit">保存内容</el-button>
                            <el-button @click="resetForm">重  置</el-button>
                            <el-button type="info" @click="backPre">返  回</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </div>
</template>
<script>
export default {
    name: "AddCase",
    components: {
    },
    data() {
        return {
            id:"",
            form: {
            },
            repairPeoList:[{
                id:"asdasdasda",
                name:"张三",
                desc:"家电类"
            },{
                id:"asdasdas123123da",
                name:"王五",
                desc:"数码类"
            }]
        }
    },
    created(){
        const that = this;
        if(that.$route.query.id){
            that.id = that.$route.query.id;
            that.initForm();
        }
    },
    methods: {
        /**保存案件信息 */
        onSubmit(){
            console.log(this.form);
            const that = this;
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    that.request.post("case/saveOrEdit", that.form).then(res => {
                        if (res.code === 200) {
                            that.$refs['form'].resetFields();
                            that.backPre();
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        initForm(){
            const that = this;
            that.request.get("case/getById",{id:that.id}).then(res =>{
                that.form = JSON.parse(JSON.stringify(res.data));
            });
        },
        /**重置表单信息 */
        resetForm(){
            this.$refs['form'].resetFields();
            this.form={};
        },
        /**返回上一页 */
        backPre() {
            this.$router.go(-1)
        }
    }
}
</script>
<style lang="less" scoped>
.add-case-page {
}
</style>